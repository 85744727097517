.registration {
  margin: 6rem 0 2rem;
  padding: 4rem 1.5rem;
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 130, 218, 0.9),
      rgba(0, 130, 218, 0.6)
    ),
    url("../../../public/form-bg.png");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__heading {
    color: #fff;
    font-size: 2.4rem;
    margin-bottom: 3rem;
  }

  &__form {
    background-color: #fff;
    width: 100%;
    max-width: 80rem;
    margin-left: 13%;
    padding: 1.5rem 3rem 1.5rem 15rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media only screen and (max-width: 1000px) {
      margin: 18rem 0 0 0;
      padding: 10rem 2rem 2rem;
    }

    &_broachure {
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 15px 0px;
      width: 22rem;
      position: absolute;
      left: -15%;
      top: 50%;
      bottom: 50%;
      transform: translateY(-50%);

      @media only screen and (max-width: 1000px) {
        width: 18rem;
        top: -18rem;
        left: 50%;
        right: 50%;
        transform: translateY(0);
        transform: translateX(-50%);
      }
    }

    &_heading {
      font-size: 2.4rem;
      margin-bottom: 2.5rem;

      @media only screen and (max-width: 1000px) {
        align-self: center;
        margin-bottom: 3rem;
      }
    }

    &_fields {
      width: 100%;
      margin-bottom: 2.5rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2.5rem;

      @media only screen and (max-width: 1000px) {
        margin-bottom: 3rem;
      }

      @media only screen and (max-width: 420px) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 4rem;
      }

      &_input {
        display: flex;
        flex-direction: column;

        label {
          font-size: 1.8rem;
          color: #0199ff;
          margin-bottom: 0.5rem;
        }

        input {
          font-size: 1.5rem;
          padding: 1px 3px;
          border: none;
          border-bottom: 2px solid #000;
          transition: all 200ms ease-in-out;

          &:focus {
            outline: none;
            border-color: #0199ff;
          }
        }
      }
    }

    &_radios {
      margin-bottom: 2.5rem;

      h3 {
        color: #0199ff;
        font-size: 1.8rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
      }

      p {
        color: rgba(#045388, 0.7);
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
      }

      &_input {
        margin-bottom: 2.5rem;

        &_options {
          label {
            font-size: 1.6rem;
            display: flex;
            align-items: center;

            input {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }

    &_btn {
      background-color: #0199ff;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 700;
      letter-spacing: 1px;
      padding: 1rem 4rem;
      border: none;
      outline: none;
      border-radius: 2rem;
      align-self: flex-end;
      transition: all 150ms ease-in-out;

      @media only screen and (min-width: 600px) {
        cursor: pointer;
      }

      @media only screen and (max-width: 1000px) {
        align-self: center;
      }

      &:focus {
        outline: 4px solid rgba(#0199ff, 0.5);
      }
    }
  }
}
