.header {
  background-image: radial-gradient(
    100% 100% at 50% 0%,
    #0199ff 75%,
    transparent 75%
  );

  &__nav {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_logo1 {
      padding: 0.5rem;
      background-color: #fff;
      border-radius: 50%;

      img {
        width: 8rem;

        @media only screen and (max-width: 800px) {
          width: 6rem;
        }
      }
    }

    &_logo2 img {
      width: 9rem;

      @media only screen and (max-width: 800px) {
        width: 7rem;
      }
    }
  }

  &__heading {
    color: #fff;
    text-align: center;
    position: relative;
    top: -4.5rem;

    @media only screen and (max-width: 800px) {
      top: -3rem;
    }

    h1 {
      font-size: 3rem;

      @media only screen and (max-width: 800px) {
        font-size: 2.6rem;
      }
    }

    h2 {
      font-size: 1.8rem;
      font-weight: 400;

      @media only screen and (max-width: 800px) {
        font-size: 1.6rem;
      }
    }
  }

  &__hero_img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 100%;
      max-width: 700px;
      position: relative;
      top: -3rem;

      @media only screen and (max-width: 800px) {
        top: -1.5rem;
      }
    }

    &_title {
      text-align: center;
      background: linear-gradient(to right bottom, #0199ff, #49ceff);
      box-shadow: rgba(100, 100, 111, 0.5) 0px 0px 10px 0px;
      border-radius: 2rem;
      overflow: hidden;
      position: absolute;
      bottom: -1.1rem;

      @media only screen and (max-width: 800px) {
        bottom: -2.6rem;
      }

      @media only screen and (max-width: 450px) {
        bottom: -2.4rem;
      }

      h3 {
        font-size: 1.7rem;
        font-weight: 400;
        color: #fff;
        padding: 1.2rem;

        @media only screen and (max-width: 450px) {
          font-size: 1.5rem;
        }
      }

      p {
        font-size: 1.5rem;
        background-color: #fff;
        padding: 1rem;

        @media only screen and (max-width: 450px) {
          font-size: 1.4rem;
        }
      }
    }
  }
}
