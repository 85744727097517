.about {
  text-align: center;

  &__heading {
    font-size: 2.4rem;
    margin: 4rem 0;
  }

  &__details {
    &_item {
      max-width: 70rem;
      box-shadow: rgba(100, 100, 111, 0.5) 0px 0px 10px 0px;
      border-radius: 2rem;
      margin: 5rem auto;

      &_photo {
        height: 6rem;
        background: linear-gradient(to bottom, #0199ff, #49ceff);
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        position: relative;

        img {
          width: 10rem;
          position: absolute;
          left: 50%;
          right: 50%;
          top: 50%;
          bottom: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &_description {
        font-size: 1.4rem;
        background-color: #fff;
        padding: 3rem 1rem 1rem;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
      }
    }
  }
}
