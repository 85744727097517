.footer {
  font-size: 1.4rem;
  background-color: #4daae9;
  color: #fff;

  &__list {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(2, 36rem);
    grid-template-rows: repeat(3, 1fr);
    gap: 2rem;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 750px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }

    &_item {
      justify-self: center;

      a {
        display: flex;
        align-items: center;

        svg {
          font-size: 4rem;
          margin-right: 1rem;
        }

        span {
          width: 90%;
        }
      }
    }
  }

  &__copyright {
    background-color: #0082da;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    padding: 1rem;
  }
}
