.admin {
  min-height: 100vh;

  &__heading {
    color: #333;
    font-size: 2.6rem;
    text-align: center;
    margin: 2rem 0;

    @media only screen and (max-width: 800px) {
      text-align: start;
    }
  }

  &__table {
    text-align: center;
    border: 2px solid #0199ff;
    margin: 0 auto;
    overflow-x: scroll;

    &_head {
      background-color: #0199ff;
      color: #fff;

      th {
        font-size: 1.4rem;
        font-weight: 700;
        padding: 1rem;
      }
    }

    &_body {
      background-color: rgba(#0199ff, 0.3);

      td {
        font-size: 1.2rem;
        font-weight: 400;
        padding: 1rem;
      }
    }
  }
}
