/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

/* Global Styling */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;

  @media only screen and (max-width: 600px) {
    font-size: 50%;
  }
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  background-color: #fff;
  color: #000;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 90%;
  max-width: 150rem;
  margin: 0 auto;
}
